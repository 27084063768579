import React from 'react'
import styled from 'styled-components'

const SvgWrap = styled.svg`
  height: 16px;
  width: 16px;
  margin: 5px 0px 0px 5px;
`

const Color = styled.path`
  fill: ${ ({ theme }) => theme.primary2}
`

const WhiteListIcon = () => (
  <SvgWrap
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 489.327 489.327'
    >
  <Color d="M478.483,273.213l-9.2-12.6c-7.6-10.4-7.7-24.5-0.3-35.1l9-12.7c10.4-14.8,5.6-35.3-10.4-43.9l-13.7-7.4
    c-11.4-6.1-17.6-18.8-15.5-31.5l2.6-15.4c3-17.8-10.3-34.3-28.4-35l-15.6-0.7c-12.9-0.5-24-9.3-27.6-21.7l-4.3-15
    c-5-17.4-24.1-26.4-40.8-19.2l-14.3,6.2c-11.9,5.1-25.6,2.1-34.3-7.6l-10.4-11.6c-12.1-13.5-33.2-13.3-45.1,0.3l-10.2,11.8
    c-8.5,9.7-22.2,13-34.1,8.1l-14.4-5.9c-16.7-6.9-35.7,2.4-40.5,19.9l-4.1,15c-3.3,12.6-14.2,21.5-27.1,22.2l-15.6,0.9
    c-18.1,1-31.1,17.7-27.8,35.4l2.8,15.3c2.3,12.7-3.7,25.5-15,31.7l-13.6,7.6c-15.8,8.8-20.4,29.4-9.7,44l9.2,12.6
    c7.6,10.4,7.7,24.5,0.3,35.1l-9,12.7c-10.4,14.8-5.6,35.3,10.4,43.9l13.7,7.4c11.4,6.1,17.6,18.8,15.5,31.5l-2.7,15.3
    c-3,17.8,10.3,34.3,28.4,35l15.6,0.7c12.9,0.5,24,9.3,27.6,21.7l4.3,15c5,17.4,24.1,26.4,40.8,19.2l14.3-6.2
    c11.9-5.1,25.6-2.1,34.3,7.5l10.4,11.6c12.1,13.5,33.2,13.3,45.1-0.3l10.2-11.8c8.5-9.7,22.2-13,34.1-8.1l14.4,5.9
    c16.7,6.9,35.7-2.4,40.5-19.9l4.1-15c3.4-12.4,14.4-21.3,27.3-22.1l15.6-0.9c18.1-1,31.1-17.7,27.8-35.4l-2.8-15.3
    c-2.3-12.7,3.7-25.5,15-31.7l13.6-7.6C484.583,308.513,489.083,287.813,478.483,273.213z M244.683,402.713
    c-87.2,0-157.9-70.7-157.9-157.9s70.7-157.9,157.9-157.9s157.9,70.7,157.9,157.9S331.883,402.713,244.683,402.713z"/>
  <Color d="M224.483,249.013l-14-14.1c-8.3-8.4-21.9-8.4-30.3-0.1l0,0c-8.4,8.3-8.4,21.9-0.1,30.3l14,14.1l15.6,15.6
    c8.1,8.2,21.3,8.2,29.5,0.1l15.6-15.5l54.4-54.1c8.4-8.3,8.4-21.9,0.1-30.3l0,0c-8.3-8.4-21.9-8.4-30.3-0.1L224.483,249.013z"/>
  </SvgWrap>
)

export default WhiteListIcon
